import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./screens/Home";
import Contact from "./screens/Contact";
import Donate from "./screens/Donate";
import Footer from "./screens/Footer";
import NotFound from "./screens/NotFound";
import CulturalSocial from "./screens/programs/CulturalSocial";
import EconomicEmpowerment from "./screens/programs/EconomicEmpowerment";
import SustainableDevelopment from "./screens/programs/SustainableDevelopment";
import AdvocacyLeadership from "./screens/programs/AdvocacyLeadership";
import Education from "./screens/programs/Education";
import HealthWellness from "./screens/programs/HealthWellness";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="donate" element={<Donate />} />
        <Route path="/programs/education" element={<Education />} />
        <Route path="/programs/healthandwellness" element={<HealthWellness />} />
        <Route path="/programs/leadership" element={<AdvocacyLeadership />} />
        <Route path="/programs/economicempowerment" element={<EconomicEmpowerment />} />
        <Route path="/programs/csawareness" element={<CulturalSocial />} />
        <Route path="/programs/sustainability" element={<SustainableDevelopment />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
