const programs=[
    {
        title: "Education",
        description: "Our educational programs are designed to inspire and empower learners of all ages through engaging and interactive experiences.",
        imageSrc: "tuition.jpg",
        imageAlt: "image of students in their graduation gowns.",
        // url: "education"
    },
    {
        title: "Health and Wellness",
        description: "Our initiative is dedicated to promoting holistic well-being and empowering individuals to lead healthier, more balanced lives.",
        imageSrc: "upgrades.jpg",
        imageAlt: "...",
        // url: "healthandwellness",
    },
    {
        title: "Economic Empowerment",
        description: "We are committed to fostering financial independence and economic growth within communities.",
        imageSrc: "upgrades.jpg",
        imageAlt: "...",
        // url: "economicempowerment",
    },
    {
        title: "Advocacy and Leadership",
        description: "We are dedicated to fostering a culture of advocacy and leadership that drives positive change and addresses critical social issues.",
        imageSrc: "upgrades.jpg",
        imageAlt: "...",
        // url: "leadership",
    },
    {
        title: "Support",
        description: "Our objective is to provide essential resources and assistance to enhance the well-being and resilience of individuals and communities.",
        imageSrc: "upgrades.jpg",
        imageAlt: "...",
        // url: "support",
    },
    {
        title: "Cultural and Social Awareness",
        description: "We aim to promote understanding and appreciation of diverse cultures and social issues to foster a more inclusive and empathetic society.",
        imageSrc: "upgrades.jpg",
        imageAlt: "...",
        // url: "csawareness",
    },
    {
        title: "Environmental and Sustainable Development",
        description: "We strive to advance environmental stewardship and sustainable practices to protect the planet for future generations.",
        imageSrc: "upgrades.jpg",
        imageAlt: "...",
        // url: "sustainability",
    },
];

const accomplishments = [
    {
        title: "Business grants",
        count: "2000",
        color: "secondary" //danger, info, light
    },
    {
        title: "Skill Workshops",
        count: "5"
    },
    {
        title: "Feed the homeless events",
        count: "24",
        color: "dark"
    },
];

const config = {
    programs,
    accomplishments,
}

export default config;