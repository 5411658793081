import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import React from "react";
import Background from "../../components/Background";
import CustomCarousel from "../../components/CustomCarousel";
const carouselData=[];

// const carouselData=[
//     {
//         "url": "https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg",
//         "title": "Assembly hall in Ansar Ud Dean, Yemaja, Ondo Town",
//         "details": "Started: 2022-09-09. Status: Ongoing."
//     },
//     {
//         "url": "https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg",
//         "title": "Assembly hall in Ansar Ud Dean, Oke Lisa, Ondo Town",
//         "details": "Started: 2022-09-09. Status: Ongoing."
//     },
//];

const AdvocacyLeadership=()=>{
    return (
        <div>
            <Background heading="School Upgrades" subheading={"Every student deserves access to high-quality education."} donateText="HELP US HELP THEM" />
            <div className="main-content">
                <MDBRow>
                <MDBCol md="3">
                    <img src={require("../../images/upgrades.jpg")} alt="..." width="100%"/>
                </MDBCol>
                    <MDBCol md="9">
                        **Advocacy and Leadership Development**
                        **Leadership Workshops**: Train women in leadership skills and encourage them to take on leadership roles in their communities.
                        **Policy Advocacy**: Advocate for policies that promote gender equality and women's rights.
                        **Community Organizing**: Support women in organizing community initiatives and movements.
                    </MDBCol>
                </MDBRow>
                <br />
                {carouselData.length>0&&
                <div>
                    <h3 className="text-center">Past, Ongoing and Upcoming Projects</h3>
                    <CustomCarousel carou={carouselData}/>
                </div>}
            </div>
        </div>
    );
}
export default AdvocacyLeadership;