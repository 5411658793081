import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import React from "react";
import Background from "../../components/Background";
import CustomCarousel from "../../components/CustomCarousel";

// const carouselData=[
//     {
//         "url": "https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg",
//         "title": "Fatima",
//         "details": "Financial assistance to study at the University of xxx"
//     },
// ];
const carouselData=[];

const Education=()=>{
    return (
        <div>
            <Background heading="School and Exam Fees" subheading={"We understand the importance of education and how it can transform lives."} donateText="HELP OUR GIRLS REACH THEIR FULL POTENTIAL" />
            <div className="main-content">
                <MDBRow>
                    <MDBCol md="3">
                        <img src={require("../../images/students.jpg")} alt="..." width="100%"/>
                    </MDBCol>
                    <MDBCol md="9">
                        Our mission is to foster a love for learning and to provide valuable knowledge and skills that will benefit individuals and communities. We provide the following educational programs:
                        <ul>
                            <li>Adult Literacy Classes: We provide literacy classes for women who didn't have access to formal education.</li>
                            <li>STEM Workshops: We encourage young women to pursue careers in Science, Technology, Engineering and Mathematics through workshops and mentorship.</li>
                            <li>Scholarship Programs: We offer scholarships for higher education to underprivileged women.</li>
                        </ul>
                    </MDBCol>
                </MDBRow>
                <br />
                {carouselData.length>0&&
                <div>
                    <h3 className="text-center">Our Scholarship Recipients</h3>
                    <CustomCarousel carou={carouselData}/>
                </div>}
            </div>
        </div>
    );
}
export default Education;