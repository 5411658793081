import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import React from "react";
import Background from "../../components/Background";
import CustomCarousel from "../../components/CustomCarousel";

// const carouselData=[
//     {
//         "url": "https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg",
//         "title": "Fatima",
//         "details": "Financial assistance to study at the University of xxx"
//     },
// ];
const carouselData=[];

const EconomicEmpowerment=()=>{
    return (
        <div>
            <Background heading="School and Exam Fees" subheading={"We understand the importance of education and how it can transform lives."} donateText="HELP OUR GIRLS REACH THEIR FULL POTENTIAL" />
            <div className="main-content">
                <MDBRow>
                    <MDBCol md="3">
                        <img src={require("../../images/students.jpg")} alt="..." width="100%"/>
                    </MDBCol>
                    <MDBCol md="9">
                        We aim to equip individuals with the skills, knowledge, and resources needed to achieve financial stability and pursue their entrepreneurial ambitions. 
                        <ul>
                            <li>Entrepreneurship Training: We offer training and resources for women interested in starting their own businesses.</li>
                            <li>Microfinance Loans: We provide small loans to women entrepreneurs to help them start or expand their businesses.</li>
                            <li>Job Skills Training: We develop programs to teach skills such as computer literacy, financial management, and vocational training.</li>
                        </ul>
                    </MDBCol>
                </MDBRow>
                <br />
                {carouselData.length>0&&
                <div>
                    <h3 className="text-center">Our Scholarship Recipients</h3>
                    <CustomCarousel carou={carouselData}/>
                </div>}
            </div>
        </div>
    );
}
export default EconomicEmpowerment;