import React from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardImage, //MDBCarousel, MDBCarouselItem,
    MDBCardFooter, MDBCardTitle, MDBCardText, MDBBtn, MDBBadge
} from "mdb-react-ui-kit";
import config from "../config";
import Background from "../components/Background";

const Home=()=>{
    return (
        <div>
            <Background heading="Home" />
            <div className="main-content">
                <p className="para">MISSION</p>
                <p>
                    At <span style={{color: "green"}}>{process.env.REACT_APP_NAME}</span>, our mission is to empower disadvantaged individuals and communities across Africa by providing essential resources and support. We are committed to organizing and managing comprehensive empowerment packages, including food, welfare, healthcare, and educational support, to uplift children, youths, orphans, widows, women, and the homeless. Through community empowerment networks, we aim to assist micro and small business owners with free business advice, grants, and collaborative support. Additionally, we focus on organizing programs, seminars, and skill acquisition opportunities to alleviate poverty and foster educational and personal growth. By sourcing mentors and facilitating access to essential resources, we strive to enhance the well-being and self-sufficiency of those we serve.
                </p>
                <p className="para">VISION</p>
                <p>To cultivate a thriving and empowered community across Africa where every individual has the opportunity to achieve their full potential. We envision a future where disadvantaged individuals are uplifted through comprehensive support, where small businesses and community networks flourish with collaborative assistance, and where educational and skill development opportunities are accessible to all. By fostering a culture of empowerment, collaboration, and mentorship, we aspire to create a just and prosperous society where poverty is alleviated, and every person has the means to contribute meaningfully to their community.</p>
                <br />
                <h2 className="text-center" style={{color: "#00008B", textDecoration: "underline"}}>OUR PROGRAMS</h2>
                <div>
                    <MDBRow>
                    {config.programs.map((p, i)=>(
                        <MDBCol md="4" key={i}>
                        <MDBCard className="h-100">
                            <MDBCardImage src={require(`../images/${p.imageSrc}`)} alt={p.imageAlt} position="top" width="375" height="250"/>
                            <MDBCardFooter>
                                <MDBCardTitle>{p.title.toUpperCase()}</MDBCardTitle>
                                <MDBCardText>
                                    {p.description.substring(0, 200)}
                                    {p.description.length>200&&<span> ...</span>}
                                </MDBCardText>
                                {p.url&&<MDBBtn style={{background: "linear-gradient(green, brown)"}} href={`/programs/${p.url}`}>LEARN MORE</MDBBtn>}
                            </MDBCardFooter>
                        </MDBCard>
                        </MDBCol>
                    ))}
                    </MDBRow>
                </div>
                <div className="mt-3 text-center" style={{borderStyle: "inset"}}><h3 style={{color: "#330033"}}>Our Impact</h3>
                    {config.accomplishments.map((a, i)=>(
                        <MDBBadge key={i} className="m-2" color={a.color} light>
                        <MDBBadge className="m-2" pill color={a.color==="dark"?"light":"dark"} light>{a.count}</MDBBadge>{a.title}
                        </MDBBadge>
                    ))}
                </div>
                <div className="mt-3 text-center" style={{background: "#1C202D", color: "#eee"}}>
                    <br />Total Raised Since Month Year<br />
                    <span style={{fontSize: 40}}><span style={{textDecorationLine: "line-through", textDecorationStyle: "double"}}>N</span> {0}</span><br />
                </div>
            </div>
        </div>
    );
}

export default Home;