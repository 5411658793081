import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import React from "react";
import Background from "../../components/Background";
import CustomCarousel from "../../components/CustomCarousel";

// const carouselData=[
//     {
//         "url": "https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg",
//         "title": "Fatima",
//         "details": "Financial assistance to study at the University of xxx"
//     },
// ];
const carouselData=[];

const HealthWellness=()=>{
    return (
        <div>
            <Background heading="School and Exam Fees" subheading={"We understand the importance of education and how it can transform lives."} donateText="HELP OUR GIRLS REACH THEIR FULL POTENTIAL" />
            <div className="main-content">
                <MDBRow>
                    <MDBCol md="3">
                        <img src={require("../../images/students.jpg")} alt="..." width="100%"/>
                    </MDBCol>
                    <MDBCol md="9">
                        Our Health and Wellness programs are designed to inspire positive change and help individuals achieve a state of well-being that enhances their quality of life. We believe that wellness is a vital component of a thriving community, and we strive to provide resources and support that address physical, mental, and emotional health.
                        <ul>
                            <li>Reproductive Health Education: We conduct workshops on reproductive health, family planning and prenatal care.</li>
                            <li>Mental Health Support: We provide counseling services, support groups, and mental health awareness campaigns.</li>
                            <li>Fitness and Nutrition Classes: We promote healthy lifestules through fitness classes and nutrition education.</li>
                        </ul>
                    </MDBCol>
                </MDBRow>
                <br />
                {carouselData.length>0&&
                <div>
                    <h3 className="text-center">Our Scholarship Recipients</h3>
                    <CustomCarousel carou={carouselData}/>
                </div>}
            </div>
        </div>
    );
}
export default HealthWellness;