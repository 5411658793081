import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import React from "react";
import Background from "../../components/Background";
import CustomCarousel from "../../components/CustomCarousel";

const carouselData=[];

// const carouselData=[
//     {
//         "url": "https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg",
//         "title": "Adeyemo – mom of 3, funds to sell food stuff from stall in front of their house",
//         // "details": "Started: 2022-09-09. Status: Ongoing."
//     },
// ];

const SustainableDevelopment=()=>{
    return (
        <div>
            <Background heading="Small Business Funds" subheading={"We are focused on building a more inclusive and sustainable economy"} donateText="HELP BUILD A SMALL BUSINESS" />
            <div className="main-content">
                <MDBRow>
                    <MDBCol md="3">
                        <img src={require("../../images/smallbusiness.jpg")} alt="..." width="100%"/>
                    </MDBCol>
                    <MDBCol md="9">
                        **Environmental and Sustainable Development Initiatives**
                        **Sustainability Training**: Educate women on sustainable practices and green technologies.
                        **Community Gardening Projects**: Encourage women to participate in community gardening for food security and environmental stewardship.
                    </MDBCol>
                </MDBRow>
                <br />
                {carouselData.length>0&&
                <div>
                    <h3 className="text-center">Small Business Fund Recipients</h3>
                    <CustomCarousel carou={carouselData}/>
                </div>}
            </div>
        </div>
    );
}
export default SustainableDevelopment;