import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import React from "react";
import Background from "../../components/Background";
import CustomCarousel from "../../components/CustomCarousel";

const carouselData=[
    {
        "url": "/images/lecture_series.jpg",
        "title": "Lecture series in Ondo town for 4 consecutive saturdays with over 180 women in attendance.",
    },
];

const CulturalSocial=()=>{
    return (
        <div>
            <Background heading="S.O.Alimi Competition" subheading={"Promoting Education and Community Engagement."} donateText="SUPPORT THE S.O.ALIMI COMPETITION" />
            <div className="main-content">
                <MDBRow>
                    <MDBCol md="3">
                        <img src={require("../../images/soalimi.jpg")} alt="..." width="100%"/>
                    </MDBCol>
                    <MDBCol md="9">
                        **Cultural and Social Awareness**
                        **Cultural Competency Workshops**: Educate communities about the cultural and social barriers women face.
                        **Art and Expression Programs**: Offer programs in arts, music, and creative writing to help women express themselves and heal.
                    </MDBCol>
                </MDBRow>
                <br />
                {carouselData.length>0&&
                <div>
                    <CustomCarousel carou={carouselData} blkText={"Our Events"}/>
                </div>}
            </div>
        </div>
    );
}
export default CulturalSocial;